exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-customer-requirements-js": () => import("./../../../src/templates/customer-requirements.js" /* webpackChunkName: "component---src-templates-customer-requirements-js" */),
  "component---src-templates-datasheet-js": () => import("./../../../src/templates/datasheet.js" /* webpackChunkName: "component---src-templates-datasheet-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-farming-gt-js": () => import("./../../../src/templates/farming-gt.js" /* webpackChunkName: "component---src-templates-farming-gt-js" */),
  "component---src-templates-field-days-js": () => import("./../../../src/templates/field-days.js" /* webpackChunkName: "component---src-templates-field-days-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-inquiry-form-js": () => import("./../../../src/templates/inquiry-form.js" /* webpackChunkName: "component---src-templates-inquiry-form-js" */),
  "slice---src-slices-collaborations-js": () => import("./../../../src/slices/Collaborations.js" /* webpackChunkName: "slice---src-slices-collaborations-js" */),
  "slice---src-slices-events-js": () => import("./../../../src/slices/Events.js" /* webpackChunkName: "slice---src-slices-events-js" */),
  "slice---src-slices-footer-js": () => import("./../../../src/slices/Footer.js" /* webpackChunkName: "slice---src-slices-footer-js" */),
  "slice---src-slices-inquiry-form-js": () => import("./../../../src/slices/InquiryForm.js" /* webpackChunkName: "slice---src-slices-inquiry-form-js" */),
  "slice---src-slices-navigation-bar-js": () => import("./../../../src/slices/NavigationBar.js" /* webpackChunkName: "slice---src-slices-navigation-bar-js" */),
  "slice---src-slices-no-translation-error-js": () => import("./../../../src/slices/NoTranslationError.js" /* webpackChunkName: "slice---src-slices-no-translation-error-js" */),
  "slice---src-slices-press-mentions-js": () => import("./../../../src/slices/PressMentions.js" /* webpackChunkName: "slice---src-slices-press-mentions-js" */)
}

